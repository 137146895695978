<template>
  <section class="mt-2">
    <div class="d-flex align-items-center flex-column mb-2">
      <b-avatar class="size-30-icons mb-2">
        <b-img :src="getIconWhite('instagram')" :class="getIconColor('instagram')" fluid></b-img>
      </b-avatar>
      <h1><span class="text-blue">¡Gratis!</span> Encuentra influencers de Instagram en tu nicho</h1>
      <p class="avenir-medium">Solo escribe tu palabra clave a continuación para encontrar influencers en tu nicho de mercado. Nuestra herramienta buscará en el contenido y en la bio del influencer.</p>
    </div>

    <div class="mb-3 d-flex flex-column align-items-center">
      <b-input-group class="mb-1 mb-sm-2 input-group-free-tool-influencers">
        <b-form-input @keyup.enter="getData()" autocomplete="off" v-model="keyword" placeholder="Introduce las palabras clave..." class="input-free-tool-influencers" id="input-group-free-influencers"></b-form-input>
        <b-input-group-append class="d-none d-sm-flex">
          <b-button variant="blue-button" class="blue-button" @click="getData()">{{loading ? 'Cargar...' : 'Verificar'}}</b-button>
        </b-input-group-append>
      </b-input-group>
      <!-- Button for responsive only -->
      <b-button variant="blue-button" class="blue-button d-sm-none w-100 mb-2" @click="getData()">{{loading ? 'Cargar...' : 'Verificar'}}</b-button>
      <!-- -------------------------- -->
      <div class="d-flex align-items-center flex-wrap">
        <p class="mb-1 mr-1 avenir-medium">Ejemplos de palabras clave</p>
        <b-badge @click="keyword = i" v-for="i in badges" :key="i" class="mr-1 mb-1 secondary-light-badge" variant="secondary-light-badge">{{ i }}</b-badge>
      </div>
    </div>


    <p v-if="total_results !== null" class="w-100 text-center">{{ getFormat(total_results) }} influencers <span class="avenir-medium">encontrados en {{ old_keyword }}</span></p>
    <b-table
      :fields="$t('freeTools')"
      :items="influencers"
      id="table-searcher-free-tool"
      head-variant="light"
      ref="table_searcher"
      v-if="total_results !== null"
      responsive
    >
      <template #custom-foot>
        <b-tr class="position-relative h-300">
          <div class="d-flex flex-column align-items-center justify-content-center box-empty-state-free-influencers">
            <feather-icon icon="LockIcon" size="30" class="mb-2"></feather-icon>
            <h4 class="mb-1"> {{ getFormat(total_results) }} influencers con la palabra clave {{ old_keyword }} en de más de 24M en la base de datos</h4>
            <b-button variant="blue-button" class="blue-button" v-if="!user_data" @click.once="$router.push({name: 'auth-register'})">Regístrate GRATIS para ver 10 resultados más</b-button>
          </div>
        </b-tr>
      </template>
      <template #cell(name)="data">
        <div class="d-flex align-items-center justify-content-between">
          <b-media vertical-align="center" @click="redirectInnerNet(data.item.username.name.username)" class="cursor-pointer">
            <template #aside>
              <div style="position: relative">
                <b-avatar
                  size="40px"
                  :src="data.item.username.avatar"
                  variant="light-primary"
                />
              </div>
            </template>
            <div class="d-flex flex-column justify-content-center media-container-avatar">
              <span class="d-flex align-items-center full-name-influencer-hover">
                {{ data.item.username.name.fullname }} <image-verified v-if="data.item.username.name.is_verified" class="ml-05"/>
              </span>
              <span v-if="data.item.username.name.username" class="avenir-light d-block text-nowrap mb-0 text-muted-username">
                @{{ data.item.username.name.username }}
              </span>
            </div>
          </b-media>
          <div @click="redirectToNet(data.item.username.name.username)">
            <b-avatar class="size-18-icons">
              <b-img :src="getIconWhite('instagram')" fluid class="padding-03"></b-img>
            </b-avatar>
          </div>
        </div>
      </template>
      <template #cell(followers)="data">
        <p class="avenir-medium d-block text-nowrap mb-0">
          {{ data.item.followers ? getFormat(data.item.followers) : '0' }}
        </p>
      </template>
      <template #cell(er)="data">
        <p class="avenir-medium d-block text-nowrap mb-0">
          {{ data.item.tp }} %
        </p>
      </template>
      <template #cell(audience)="data">
        <p class="avenir-medium d-block text-nowrap mb-0">
          {{ data.item.audience ? getFormat(data.item.audience) : '0'}}
        </p>
      </template>
      <template #cell(categories)="data">
        <div class="h-16 position-relative" v-if="data.item.categories && data.item.categories.all_categories.length > 0">
          <span 
            class="avatar-categories"
            :id="`categories-list-unique-${data.item.id}`"
            v-for="(i) in data.item.categories.all_categories.length" :key="i"
            :style="getStyle(i)"
            @mouseover="updatePopover(data.item.categories.all_categories, `categories-list-unique-${data.item.id}`)"
          >
          {{ data.item.categories.first_category }}
          </span>
        </div>
      </template>
    </b-table>

    <b-popover v-if="popover_target" :target="popover_target" triggers="hover" placement="left" custom-class="popover-categories" :key="update_popover">
      <div v-html="popover_content" class="text-white"></div>
    </b-popover>
  </section>
</template>

<script>
import { 
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  BAvatar,
  BImg,
  BTable,
  BMedia,
  BPopover,
  BTr,
} from 'bootstrap-vue'
import { getIconColor, getIconWhite } from '@/libs/utils/icons';
import { getFormat } from '@/libs/utils/formats';
import { getURL } from '@/libs/utils/urls';
import { getUserData } from '@/auth/utils';
import searcher_services from '@/services/search';

export default {
  name: 'FindInfluencers',
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BBadge,
    BAvatar,
    BImg,
    BTable,
    BMedia,
    BPopover,
    BTr,
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue'),
  },
  data() {
    return {
      getIconWhite,
      getIconColor,
      getFormat,
      badges: ['cripto', 'moda', 'gaming', 'buen nivel'],
      total_categories_service: null,
      influencers: [],
      popover_target: null,
      popover_content: null,
      update_popover: 0,
      local: window.location.origin,
      loading: false,
      keyword: '',
      old_keyword: '',
      total_results: null,
    }
  },

  created() {
    this.getCategories()
  },
  computed: {
    user_data() {
      return getUserData()
    }
  },
  methods: {
    getStyle(i) {
      return `left: ${2 * i}px; z-index: ${ 10 - i}`
    },
    updatePopover(categories, id_target) {
      this.popover_content = categories.join('<br>');
      this.popover_target = id_target;
      this.update_popover += 1;
    },
    getData() {
      if (!this.loading) {
        this.loading = true;
        const data = {
          st: 'ig',
          keywords: [
            {
              keyword: this.keyword,
              type: 'include',
              where: ['content', 'description']
            }
          ]
        }
        searcher_services.freeSearcher(data).then((response) => {
          this.parseData(response.data)
          this.loading = false;
        })
      }
    },
    redirectInnerNet(username) {
      window.open(`${this.local}/perfil/instagram/${username}`, '_blank')
    },
    redirectToNet(username) {
      window.open(getURL('instagram', username), '_blank')
    },
    parseData(response) {
      this.influencers = [];

      this.total_results = response.total;

      this.old_keyword = this.keyword;
      this.keyword = '';

      const indexes = {
        account: 'un',
        followers: 'fc',
        er: 'er',
        audience: 'rfc',
        category: 'category',
      };
      const index = {};
      for (const key in indexes) {
        index[key] = response.columns.findIndex(i => i.name === indexes[key]);
      }

      response.data.forEach(item => {
        
        const row = {}
        row.id = item[0]
        row.username = {
          avatar: item[index.account].ava,
          name: item[index.account],
        }
        row.followers = item[index.followers]
        row.tp = item[index.er].toFixed(2)
        row.audience = item[index.audience]
        row.categories = this.getCategoriesLabel(item[index.category])

        this.influencers.push(row)
      })
    },
    getCategories() {
      searcher_services.getCategories('instagram').then(response => {
        this.total_categories_service = response;
      })
    },
    getCategoriesLabel(array_categories) {
      
      if (this.total_categories_service) {
        const new_m = array_categories.map((index_category) => {
          for (const key in this.total_categories_service) {
            if (this.total_categories_service[key].find(i => i.id === index_category))
              return this.total_categories_service[key].find(i => i.id === index_category).title
          }
        })
        return {all_categories: new_m, first_category: new_m[0]};
      }
      return {all_categories: [], first_category: ''};
      
    },
  },
}
</script>

<style lang="scss" scoped>
.size-30-icons {
  width: 30px;
  height: 30px;
}
.size-18-icons {
  width: 15px;
  height: 15px;
  visibility: hidden;
  cursor: pointer;

  &:hover {
    background-color: #d6249f;
  }
}
.h-300 {
  height: 300px;
}
.text-muted-username {
  color: #8490A4;
  font-size: 14px;
  font-family: 'avenir-medium';
}
.avatar-categories {
  background-color: #fcfcfc;
  border: 1px solid #E7E8E8;
  font-family: 'avenir-medium';
  padding: 0.2em 0.9em;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow-x: hidden;
}
.box-empty-state-free-influencers {
  width: 100%;
  height: 300px;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
}
</style>
<style lang="scss">
.secondary-light-badge {
  background-color: rgb(224, 224, 224) !important;
  color: #444;
  cursor: pointer;
}
.input-group-free-tool-influencers {
  box-shadow: 0 30px 30px #30607b1a, 0 0 15px #3954681a;
  background: transparent;
  border-radius: 10px;
  width: auto;
  
  .input-free-tool-influencers  {
    width: 368px !important;
    flex: none !important;
    height: 56px;
    max-width: 580px;
    border-radius: 10px;
    outline: none !important;
    border: 2px solid #0096fe;
  
    &:focus, &:active {
      border: 2px solid #0096fe !important;
    }
    @media(max-width: 575px) {
      width: 100% !important;
      border-radius: 10px;
    }  
  }
  @media(max-width: 575px) {
    width: 100%;
  }
}
.input-group-free-tool-influencers {
  .form-control:not(:last-child) {
    border: 2px solid #0096fe !important;
    @media(max-width: 575px) {
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }
}
.thead-light th {
  background-color: white !important;
}
.table-responsive {
  box-shadow: 0 15px 25px #10111317,0 -1px 10px #181a1c12;
}
#table-searcher-free-tool {
  padding: 1em;
  width: 100%;

  td {
    border-bottom: 1px solid #eff3f6;
  }
  tr {
    background-color: white;
  
    &:hover {
      .size-18-icons {
        visibility: visible;
      }
    }
  }
  thead {
    th:nth-child(5) {
      display: none;
      @media(min-width: 700px) {
        display: block;
      }
    }
  }
  tbody {
    td:nth-child(5) {
      width: 550px !important;
      display: none;
      @media(min-width: 700px) {
        display: block;
      }
    }
    tr:last-child {
      filter: blur(3px)
    }
  }
}
.h-16 {
  height: 27px;
  width: 100%;
}
.popover-categories {
  background: rgba(0, 0, 0, 0.758) !important;
  .arrow::after {
    border-left-color: rgba(0, 0, 0, 0.758) !important;
  }
}

</style>